import React from 'react';
import { Button, useTheme } from '@geist-ui/core';
import { Maximize, Minus, X } from '@geist-ui/react-icons';
import { wind } from '~/root';

const WindowControls: React.FC = () => {
    const theme = useTheme();

    const handleMinimize = () => {
        wind?.electronAPI.minimizeWindow();
    };

    const handleMaximize = () => {
        wind?.electronAPI.maximizeWindow();
    };

    const handleClose = () => {
        wind?.electronAPI.closeWindow();
    };

    const buttonStyle = {
        fontSize: '12px',
        padding: '4px',
        width: '40px',
        height: '40px',
        marginRight: 5,
        background: 'transparent',
        '-webkit-app-region': 'no-drag',
        position: 'relative',
        border: 'none',
        '&:hover': {
            background: theme.palette.accents_2,
        },
        '&:focus': {
            outline: 'none',
        },
        zIndex: 2
    } as React.CSSProperties

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            marginLeft: 15,
        }}>
            <Button auto scale={2 / 3} px={0.6}
                aria-label="close"
                iconRight={<X />}
                style={buttonStyle}
                onClick={handleClose} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            <Button auto scale={2 / 3} px={0.6}
                aria-label="maximize"
                iconRight={<Maximize />}
                style={buttonStyle}
                onClick={handleMaximize} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            <Button auto scale={2 / 3} px={0.6}
                aria-label="minimize"
                iconRight={<Minus />}
                style={buttonStyle}
                onClick={handleMinimize} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </div>
    );
};

export default WindowControls;